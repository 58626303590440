import React from "react";

// TODO: The prime button thing can probably just be local state
export const AnswerBox = ({
  answers,
  toggleRadio,
  answersAccessible,
  selectedAnswerId,
}) => (
  <div
    className={
      answersAccessible
        ? "dcm-sender-conversation-bottom-script pane"
        : "dcm-sender-conversation-bottom-script pane disabled"
    }
  >
    {answers !== undefined &&
      answers.map((p, i) => {
        return p.text === "" ? (
          <div key={p.value} />
        ) : (
          <label key={p.value} className="closed-answer">
            <input
              type="radio"
              value={i}
              checked={i === selectedAnswerId}
              onChange={toggleRadio}
            />
            <div className="answer-value">{p.value}</div>
            <div className="answer-text">{p.text}</div>
          </label>
        );
      })}
  </div>
);
