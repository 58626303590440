import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import createRootReducer from "./mainReducer";

import { fetchMiddleware } from "../middleware/fetchMiddleware";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, fetchMiddleware(fetch)];

// DEV MODE DEBUGGING
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composeEnhancers = compose(
  applyMiddleware(routerMiddleware(history), ...middleware),
  ...enhancers
);

const store = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers
);

export default store;
