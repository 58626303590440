import React, { Component } from "react";

class SelectActiveCampaignScreen extends Component {
  componentDidMount() {
    // this.getLaunchedCampaigns();
    this.props.getLaunchedCampaigns();
  }

  selectCampaign() {    
    this.props.setCampaignid(this.campaignid.value);
  }

  render() {
    const {
      loadingLaunchedCampaigns: loading,
      errorLaunchedCampaigns: error,
      launchedCampaigns,
      errorMessage
    } = this.props;

    return (
      <div>
        {loading && <div>Loading...</div> }
        {error && <div>Error! {errorMessage}</div> }

      {launchedCampaigns && launchedCampaigns.length > 0 && <React.Fragment>
        <select
          ref={(campaignid) => {
            this.campaignid = campaignid;
          }}
        >
          {launchedCampaigns
            .sort((a, b) => {
              const aN = a.name.toLocaleLowerCase();
              const bN = b.name.toLocaleLowerCase();

              return aN > bN ? 1 : bN > aN ? -1 : 0;
            })
            .map(function (c, i) {
              return (
                <option key={i} value={c.campaignid}>
                  {c.name}
                </option>
              );
            })}
        </select>
        <div className="div-28">
          <div className="button-one12" onClick={() => this.selectCampaign()}>
            Select
          </div>
        </div>
      </React.Fragment>}
      </div>
    );
  }
}

export default SelectActiveCampaignScreen;
