import React from "react";
import { reduxConnectedComponent } from "../util/reduxConnectedHOC";

const RecipientListLoadingIndicatorComponent = ({
  myListLoading,
  moreListLoading,
}) => {
  if (moreListLoading)
    return (
      <div className="dcm-sender-phonelist-loading">
        Loading more recipients...
      </div>
    );

  if (myListLoading)
    return (
      <div className="dcm-sender-phonelist-loading">
        Loading your assigned recipients...
      </div>
    );

  return null;
};

export const RecipientListLoadingIndicator = reduxConnectedComponent(
  RecipientListLoadingIndicatorComponent,
  (state) => ({
    myListLoading: state.recipients.getMyList.loading,
    moreListLoading: state.recipients.getMoreList.loading,
  })
);
