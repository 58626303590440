import React from "react";
import PropTypes from "prop-types";

const infoMap = {
  recipientresponse: "Waiting for recipient to respond.",
  answercoding: "Recipient answer is ready to be coded.",
  nextsend: "Next script quesion is ready for to be sent.",
  reattempt: "Reattempt wait time has passed for this question, and question can be reattempted.",
  conversationstart: "Conversation is ready to start and first question is ready to be sent.",
};

export const Recipient = ({
  children,
  panel,
  selected,
  updatePhone,
  phone,
  name,
  conversationStarted,
  awaitingAnswerCoding,
  awaitingNextSend,
  awaitingReattempt,
}) => {
  let statusClass = "recipientresponse";
  if (awaitingAnswerCoding) {
    statusClass = "answercoding";
  } else if (awaitingNextSend) {
    statusClass = "nextsend";
  } else if (awaitingReattempt) {
    statusClass = "reattempt";
  } else if (!conversationStarted) {
    statusClass = "conversationstart";
  }

  return (
    <div
      className={`dcm-sender-phonelist-row${panel ? " panel" : ""}${
        selected ? " selected" : ""
      } pending-${statusClass}`}
      onClick={() => updatePhone(phone)}
    >
      <div className="name">{name}</div>
      <div className="phone">{phone}</div>
      {conversationStarted && statusClass !== "recipientresponse" && (
        <div className="pointer" title={infoMap[statusClass]}>!</div>
      )}
      {children}
    </div>
  );
};

Recipient.propTypes = {
  panel: PropTypes.bool,
  selected: PropTypes.bool,
  updatePhone: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
