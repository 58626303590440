import { RecipientList } from "../../components/Recipients/RecipientList";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";

export default reduxConnectedComponent(RecipientList, (state) => {
  return {
    sortedPhones: state.recipients.sortedPhones,
    selectedCampaignCurrentList: state.recipients.numbers,
    selectedCampaignCurrentPhone: state.recipients.selectedRecipientPhone,
  };
});
