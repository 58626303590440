import React, { Component } from "react";
import { InactivityModal } from "./InactivityModal";
import {
  setTimeoutCustom,
  clearTimeoutCustom,
  setIntervalCustom,
  clearIntervalCustom
} from "../../util/helper";

const STARTING_SECONDS = 60; // 1 Minute
const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 Minutes

/**
 * Wraps the inactivity countdown and takes care of the logic of countdown and
 * handling the intervals.
 */
class InactivityWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inactive: false,
      previousLastText: new Date(0)
    };
  }

  componentDidMount() {
    if (!this.state.inactive) {
      this.startInactivityTimeout();
    }
  }

  componentDidUpdate() {
    if (
      this.props.lastTextSent &&
      this.props.lastTextSent.getMilliseconds() !==
        this.state.previousLastText.getMilliseconds()
    ) {
      this.startInactivityTimeout();
      this.setState({
        previousLastText: this.props.lastTextSent
      });
    }
  }

  componentWillUnmount() {
    // Remove both interval and timeout just to be safe.
    clearTimeoutCustom(this.state.inactivityTimeoutId);
    clearIntervalCustom(this.state.secondsIntervalId);
  }

  reset = () => {
    clearIntervalCustom(this.state.secondsIntervalId);
    this.setState(
      {
        inactive: false,
        seconds: undefined
      },
      this.startInactivityTimeout
    );
  };

  /**
   * Wait 10 minutes then set inactive to true
   */
  startInactivityTimeout = () => {
    clearTimeoutCustom(this.state.inactivityTimeoutId);
    this.setState({
      inactivityTimeoutId: setTimeoutCustom(() => {
        this.setState({ inactive: true });
        this.startSecondsInterval();
      }, INACTIVITY_TIMEOUT)
    });
  };

  /**
   * Set a timeout that decreases the seconds
   */
  startSecondsInterval = () => {
    this.setState({
      secondsIntervalId: setIntervalCustom(this.decreaseSeconds, 1000)
    });
  };

  decreaseSeconds = () => {
    let newSeconds = this.state.seconds;

    if (newSeconds === undefined) {
      newSeconds = STARTING_SECONDS;
    } else if (newSeconds === 0) {
      clearIntervalCustom(this.state.secondsIntervalId);
      newSeconds = undefined;
      this.props.logout();
    } else {
      newSeconds -= 1;
    }

    this.setState({
      seconds: newSeconds
    });
  };

  render() {
    return this.state.inactive ? (
      <InactivityModal
        secondsLeft={this.state.seconds}
        logout={this.props.logout}
        cancel={this.reset}
      />
    ) : null;
  }
}

InactivityWrapper.defaultProps = {
  lastTextSent: new Date(0)
};

export default InactivityWrapper;
