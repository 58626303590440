import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import auth from "./auth/reducer";
import campaign from "./campaign/reducer";
import recipients from "./recipients/reducer";
import selectedRecipient from "./selectedRecipient/reducer";
import agent from "./agent/reducer";
import events from "./events/reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    campaign,
    recipients,
    selectedRecipient,
    agent,
    events,
  });

export default createRootReducer;
