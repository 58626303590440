import * as actionTypes from "./types";
import { updateObject } from "../tools";
import { CLEAR_DATA } from "../common/types";

const initialState = {
  essentials: { loading: false, error: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_ESSENTIALS_IS_LOADING:
      return {
        ...state,
        essentials: updateObject(state.essentials, {
          loading: true,
          error: false,
        }),
      };
    case actionTypes.CAMPAIGN_ESSENTIALS_HAS_ERROR:
      return {
        ...state,
        essentials: updateObject(state.essentials, {
          loading: false,
          error: true,
          errorMessage: action.message,
        }),
      };
    case actionTypes.CAMPAIGN_ESSENTIALS_POST_SUCCESS:
      const data = action.data.data;
      return {
        ...state,
        ...data,
        twilioroom:
          data.socket_room,
        includesPanelOptIn: data.script.some(
          (item) => item.type === "panel"
        ),
        essentials: updateObject(state.essentials, initialState.essentials),
      };
    case actionTypes.SET_TWILIOROOM:
      return updateObject(state, { twilioroom: action.twilioroom});
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
