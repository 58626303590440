import React from "react";
import { Button } from "../../common/Button";

// TODO: The prime button thing can probably just be local state
export const AcceptAnswerButton = ({
  acceptAnswer,
  phone,
  questionType,
  answers,
  selectedAnswerId
}) => {

  let enable = false;
  if (questionType === "text") {
    enable = true;
  } else if (["closed", "panel", "intro"].includes(questionType) && answers) {
    enable = answers[selectedAnswerId] !== undefined;
  }

  return (
    <div className="buttons">
      <Button
        onClick={() => acceptAnswer(phone)}
        disabled={!enable}
        classSuffix=" dcm-sender-special-action"
      >
        Accept Answer
      </Button>
    </div>
  );
};
