import React, { Component } from "react";
import { filterSample } from "../../util/helper";
import { Button } from "../../common/Button";

class PromptServerModal extends Component {
  // Move this to redux
  togglePromptServerModal = (visible) => {
    this.props.togglePromptModal(visible);
  };

  callbackAndClose = (question) => {
    this.togglePromptServerModal(false);
    this.props.fillScript(question);
  };

  render() {
    const { prompts, variables } = this.props;

    if (!this.props.promptModalVisible) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          className="deactivatingOverlay"
          style={{
            visibility: this.props.promptModalVisible ? "visible" : "hidden",
          }}
          onClick={() => this.togglePromptServerModal(false)}
        />
        <div className="dcm-sender-promptserver-modal">
          Please Select a Prompt
          <div className="dcm-sender-promptserver-modal-scroller">
            {prompts.map((p, i) => {
              // Replace any encoding {stuff} by the appropriate.
              let question = filterSample(p.question, variables);
              return (
                <div
                  className="dcm-sender-promptserver-row"
                  onClick={() => this.callbackAndClose(question)}
                  key={i}
                >
                  {question}
                </div>
              );
            })}
          </div>
          <div className="div-28">
            <Button onClick={() => this.togglePromptServerModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PromptServerModal;
