import React, { Component } from 'react';
import * as Sentry from "@sentry/react";
import '../styles/App.css';
import "../styles/all.css";
import "../styles/sender.css";
// import DCM from "../containers/DCM";

import LandingContainer from '../screens/Landing.container';
// run();

class App extends Component {
  render () {
    return (
    <div className="App">
      <LandingContainer />
    </div>
    );
  }

}

export default Sentry.withProfiler(App);
