import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../common/Button";

export const MessageControls = ({
  togglePromptModal,
  updateConversationSender,
  fillScript,
  question,
  justsentscript,
  phone,
  pendingID,
  composedText,
  disabled=false
}) => (
  <div>
    <div className="buttons">
      <Button
        disabled={composedText === "" || justsentscript === 1 || disabled}
        onClick={() => updateConversationSender(phone, pendingID)}
        classSuffix=" dcm-sender-special-action"
      >
        {justsentscript === 1 ? "Please Wait" : "Send"}
      </Button>
    </div>
    <div className="buttons">
      <Button disabled={disabled} onClick={() => togglePromptModal(true)}>Choose Prompt</Button>
      <Button disabled={disabled} onClick={() => fillScript(question)}>Restore Question</Button>
    </div>
  </div>
);

MessageControls.prototypes = {
  togglePromptModal: PropTypes.func.isRequired,
  fillScript: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
};
