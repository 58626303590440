import React, { Component } from "react";

import { REATTEMPT_PREFIX } from "../../util/constants";
import { deepCopy, replaceMiscVarsWithValues } from "../../util/helper";
import { ScriptLogicError, evaluateConditionals } from "../../util/scriptLogic";
import deepEqual from "deep-equal";

import { AcceptAnswerButton } from "../Answers/AcceptAnswerButton";
import { AnswerBox } from "../Answers/AnswerBox";
import { MessageBox } from "../Message/MessageBox";
import RecipientListContainer from "../../screens/RecipientList/RecipientList.container";
import PromptModalContainer from "../modals/PromptModal.container";
import MessageControlsContainer from "../Message/MessageControls.container";
import { EVENT_TYPES } from "../../redux/events/actions";

class Conversation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.adaptScript();
  }

  async componentDidUpdate(prevProps, prevState) {
    await this.adaptScript(prevProps, prevState);
    this.autoSelect();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      !state.selectedPhone ||
      props.selectedCampaignPhone !== state.selectedPhone 
      ||
      (props.selectedCampaignPhone === state.selectedPhone && props.selectedListItem.conversation.length !== state.conversation.length)
    ) {
      return {
        selectedPhone: props.selectedCampaignPhone,
        selectedAnswerId: null,
        ...props.selectedListItem,
      };
    }

    return null;
  }

  adaptScript = (prevProps, prevState = {}) => {
    // Get the next script item and adapt it by inserting the information from misc.
    // Don't do this if we just sent a script because we don't want to repopulate with the same script.

    // if agent just sent last message in queue, there will be no selectedListItem
    // in that case abort until they click one
    if (!this.props.selectedCampaignPhone || !this.props.selectedListItem) {
      return;
    }

    let adaptedScript;
    try {
      adaptedScript = this.getCurrentQuestion(this.props.selectedListItem);
    } catch (e) {
      if (e instanceof ScriptLogicError) {
        const errInfo = { 
          position: e.position,
          statement: e.statement,
          parsedCondition: e.parsedCondition,
          errorType: e.errorType, 
          phone: this.props.selectedCampaignPhone,
        };
        this.props.addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "init_conditional", errInfo);
      } else {
        this.props.addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "init_conditional");
      }
      if (!prevState.selectedPhone || prevState.selectedPhone === this.props.selectedCampaignPhone) {
        // No previous phone or same phone, so we can't go back to a previous phone.
        this.props.updatePhone("");
      } else {
        // If there's a previous phone to go back to, do it.
        this.props.updatePhone(prevProps.selectedCampaignPhone);
        console.log(prevProps.selectedCampaignPhone, prevState.selectedPhone, this.props.selectedCampaignPhone, this.state.selectedPhone);
      }
      return;
    }
    

    adaptedScript.question = replaceMiscVarsWithValues(
      adaptedScript.question,
      this.props.selectedListItem.misc
    );
    adaptedScript.answers = adaptedScript.answers.map((answer) => {
      answer.text = replaceMiscVarsWithValues(
        answer.text,
        this.props.selectedListItem.misc
      );
      return answer;
    });
    adaptedScript.question = this.checkForReattemptAndApplyPrefix(
      adaptedScript.question
    );

    const scriptDidChange = [
      prevState.question === undefined ||
        !deepEqual(adaptedScript.question, prevState.question),
      prevState.pendingID === undefined ||
        !deepEqual(adaptedScript.id, prevState.pendingID),
      prevState.type === undefined ||
        !deepEqual(adaptedScript.type, prevState.type),
      // answers not compared here because it blocks selecting/accepting them,
      // but they shouldn't be changing without the question/id/type changing anyway
    ].some((changed) => changed); // true if any changed

    if (
      scriptDidChange ||
      (prevProps &&
        prevProps.selectedCampaignPhone !== this.props.selectedCampaignPhone)
    ) {
      if (this.hasAlreadyBeenSent(adaptedScript.question)) {
        console.warn(
          "Script led to a duplicate SMS! Clearing the text composer. Message was: " +
            adaptedScript.question
        );
      }
      console.log("id", adaptedScript.id);
      this.setState({
        question: adaptedScript.question, // save unaltered script for restoration
        composedText: !this.hasAlreadyBeenSent(adaptedScript.question)
          ? adaptedScript.question
          : "",
        pendingID: adaptedScript.id,
        answers: adaptedScript.answers,
        type: adaptedScript.type,
        autoSelected: false,
        // TODO: Need to test this, but we need a way to clear it when the question changes
        // selectedAnswerId: null
      });
    }
  };

  autoSelect = () => {
    const { autoSelected, conversation, answers, selectedAnswerId } = this.state;
    // NOTE: careful of falsy checks with the selectedAnswerId!!!
    // It's an index so it can be 0 which returns false
    if (autoSelected || selectedAnswerId !== null) {
      return;
    }
    const convo_length = conversation.length;
    const current_convo_item = conversation[convo_length - 1];

    if (!current_convo_item || current_convo_item.who !== "recipient") {
      return;
    }

    const current_message = current_convo_item.what.toLocaleLowerCase().trim();
    const matchIndex = answers.findIndex(
      (answer) =>
        current_message === answer.text.toLocaleLowerCase().trim() ||
        current_message === answer.value.toString().toLocaleLowerCase().trim()
    );
    if (matchIndex !== -1) {
      this.updateSelectedAnswer(matchIndex, true);
    }
  };

  evaluateInitialConditionals = () => {
    const { initialConditionals, selectedListItem, defaultFirstQuestionId, addTexterAppEvent } =
      this.props;

    return evaluateConditionals(
        initialConditionals,
        selectedListItem.misc,
        selectedListItem.conversation,
        defaultFirstQuestionId
      );
  };

  getCurrentQuestion = (selectedListItem) => {
    let nextQuestionId;

    if (selectedListItem.currentscriptid) {
      nextQuestionId = selectedListItem.currentscriptid;
    } else if (this.evaluateInitialConditionals()) {
      // initialConds assigned in condition to prevent double-evaluateInitialConditionals
      nextQuestionId = this.evaluateInitialConditionals();
    } else {
      console.warn(
        "Could not find a saved spot in the script, or an initial conditional. Restarting to first script item."
      );
      console.log("Got here");
      nextQuestionId = this.props.script[0].id;
    }
    console.log("id getq", nextQuestionId);

    return deepCopy(this.props.script.find((q) => q.id === nextQuestionId));
  };

  hasAlreadyBeenSent = (txt) => {
    if (txt === "") {
      // Protect against loops
      return false;
    }
    // Remove spaces so stripped line breaks don't cause false differences
    const thingsSent = this.props.selectedListItem.conversation
      .filter((a) => a.who === "sender")
      .map((a) => a.what.replace(/[\s\n]/g, ""));
    return thingsSent.includes(txt.replace(/[\s\n]/g, ""));
  };

  /**
   * Checks for reattempt flag and adds prefix (only once!)
   * @param {*} text the text to apply the reattempt prefix to
   * @returns altered text
   */
  checkForReattemptAndApplyPrefix = (text) => {
    return this.props.selectedListItem.reattempt &&
      !text.includes(REATTEMPT_PREFIX)
      ? REATTEMPT_PREFIX + text
      : text;
  };

  fillScript = (p) => {
    this.setState({
      composedText: p,
    });
  };

  // Update the conversation via props.
  updateConversationSender = (phone, currentQuestionId) => {
    // Update the conversation.
    this.props.updateConversationSender(
      phone,
      this.state.composedText,
      currentQuestionId
    );
    this.setState({ composedText: "" });
  };

  toggleRadio = (e) => {
    this.updateSelectedAnswer(e.target.value);
  };

  updateSelectedAnswer = (value, autoSelected = false) => {
    const intValue = Number(value);
    if (!isNaN(intValue)) {
      this.setState({ 
        selectedAnswerId: intValue,
        autoSelected,
      });
    }
  };

  // The accepted answer might have to be for a closed question.
  acceptAnswer = async (phone) => {
    const {
      answers,
      pendingID,
      type: answerType,
      selectedAnswerId,
    } = this.state;
    const { selectedListItem, updateConversationRecipient } = this.props;
    // Figure out the correct answer.
    let acceptedAnswer = "ACCEPTED";
    let acceptedValue = "NONE";
    
    // If it's closed, take the last response. This should be made nicer?
    if (answerType === "text") {
      acceptedAnswer = selectedListItem.conversation.slice(-1)[0]["what"];
    } else if (answers.length > 0) {
      // let selectedAnswer = answers.filter((a) => a.checked)[0];
      let selectedAnswer = answers[selectedAnswerId];
      acceptedAnswer = selectedAnswer.text;
      acceptedValue = selectedAnswer.value;
    }
    
    // qID in arg is where conversation should now be "bookmarked"
    // qID in {payload} is which qID the answer should be saved under
    await updateConversationRecipient(
      "accepted",
      phone,
      acceptedAnswer,
      pendingID,
      {
        value: acceptedValue,
        id: pendingID,
        type: answerType,
      }
    );

    // runs conditional logic to get next Q
    // await this.props.updateConversationNextScript(phone);
    // this.adaptScript();
  };

  senderChangedScript = (event) => {
    this.setState({ composedText: event.target.value });
  };

  checkAnswersAccessible = (convo) => {
    // No convo? No worries!
    if (!convo.length) {
      return false;
    }

    // We need the convo reversed to we can easily do a search for the previous action
    const reverseConvo = [...convo].reverse();

    
    // Never enable if the last action is from the agent
    if (
      reverseConvo[0].who === "accepted" ||
      reverseConvo[0].who === "sender"
      ) {
        return false;
      } else if (
        reverseConvo[0].scriptboolean === true ||
        reverseConvo[0].who === "recipient" ||
        reverseConvo[0].who === "bot"
        ) {
          // Before enabling answers check that this incoming message isn't a correction to the previous question.
          const firstResult = reverseConvo.find((item, i) =>
          ["accepted", "sender"].includes(item.who)
          );
      // We'll know it is if the last action before it was "accepted" and not "sender"
      if (firstResult && firstResult.who === "accepted") {
        return false;
      }
      return true;
    }
    return false;
  };

  render() {
    const { selectedListItem } = this.props;
    const phone = this.props.selectedCampaignPhone;
    const convo = this.props.selectedListItem.conversation;
    const answersAccessible = this.checkAnswersAccessible(convo);

    return (
      <div className="dcm-sender-conversation pane panel vertical">
        <PromptModalContainer
          prompts={this.props.prompts}
          fillScript={this.fillScript}
          variables={this.props.selectedListItem.misc}
        />

        {this.props.children}
        <div
          className={`dcm-sender-conversation-bottom pane panel ${
            selectedListItem.status === "closed" ||
            selectedListItem.status === "terminated"
              ? "disabled"
              : ""
          }`}
        >
          <RecipientListContainer updatePhone={this.props.updatePhone} selected={this.props.selectedCampaignPhone }/>
          <div className="pane panel vertical">
            <MessageBox
              composedText={this.state.composedText}
              nextScriptHolder={this.nextScriptHolder}
              senderChangedScript={this.senderChangedScript}
              justsentscript={selectedListItem.justsentscript}
            />
            <MessageControlsContainer
              // Need to replace this function so
              question={this.state.question}
              fillScript={this.fillScript}
              updateConversationSender={this.updateConversationSender}
              justsentscript={selectedListItem.justsentscript}
              phone={phone}
              pendingID={this.state.pendingID}
              composedText={this.state.composedText}
            />
          </div>
          <div className="pane panel vertical">
            <AnswerBox
              selectedCampaignCurrentPhone={phone}
              answers={this.state.answers}
              selectedAnswerId={this.state.selectedAnswerId}
              toggleRadio={this.toggleRadio}
              answersAccessible={answersAccessible}
            />
            <AcceptAnswerButton
              questionType={this.state.type}
              phone={phone}
              answers={this.state.answers}
              selectedAnswerId={this.state.selectedAnswerId}
              acceptAnswer={this.acceptAnswer}
            />
          </div>
        </div>
      </div>
    );
  }
}

Conversation.defualtProps = {
  initialConditionals: [],
};

export default Conversation;
