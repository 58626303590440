import { fetchRequest } from "../../middleware/fetchMiddleware";
import { SET_TWILIOROOM } from "./types";

export const getCampaignEssentials = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest("CAMPAIGN_ESSENTIALS", "POST", "/retrieveCampaignEssentials", {
      campaignid: state.auth.campaignid,
    })
  ).then(() => {
    if (!getState().campaign.twilioroom) {
      dispatch(updateTwilioRoom(`room_${getState().auth.campaignid}`));
    }
  });
};

export const updateTwilioRoom = (twilioroom) => (dispatch, getState) =>
  dispatch({
    type: SET_TWILIOROOM,
    twilioroom: twilioroom,
  });
