import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./styles/index.css";
import App from "./components/App";
import registerServiceWorker, { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import store, {history} from "./redux/store";
import { AppContainer } from "react-hot-loader";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";

Sentry.init({
  dsn: "https://9d430a442036485d9591208ed793789a@sentry.io/2824515",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

const target = document.getElementById("root");

// Modified to include hot-reloading based on this article
// https://daveceddia.com/hot-reloading-create-react-app/

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <ConnectedRouter history={history}>
          <Route path="/" component={App} />
        </ConnectedRouter>
      </AppContainer>
    </Provider>,
    target
  );

unregister();
// registerServiceWorker();

render();
