import React from "react";

export const Button = ({
  disabled,
  classSuffix = "",
  classPrefix = "",
  children,
  ...props
}) => {
  let classResults = "button-one12";
  classResults = classPrefix + classResults + classSuffix;

  if (disabled) {
    classResults += " disabled";
  } else {
  }

  return (
    <button
      className={classResults}
      {...props}
    >
      {children}
    </button>
  );
};
