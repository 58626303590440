import { getConversationNextScript } from "./selectors";
import {
  UPDATE_CONVERSATION_NEXT_SCRIPT,
  TOGGLE_RECIPIENT_REATTEMPT,
  UPDATE_LAST_TEXT_SENT,
  UPDATE_SELECTED_RECIPIENT,
  SELECT_NEXT_RECIPIENT,
  ADD_ITEM_TO_CONVERSATION,
  UPDATE_ACTIONABLE_CONVERSATION_COUNT,
  TOGGLE_RECIPIENT_JUSTSENTSCRIPT,
  REMOVE_CONVERSATION,
  SET_CONVO_TERMINATING,
} from "./types";

/**
 * Sets the recipient currentscriptid to the proper next script
 * @param {*} phone
 */
export const updateConversationNextScript = (phone) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_CONVERSATION_NEXT_SCRIPT,
    phone,
    // NOTE: Don't like this wrapper at all! This then get's called all over the place.
    nextScriptId: getConversationNextScript(getState(), phone),
  });
};
export const toggleRecipientReattempt = (phone, reattempt) => (dispatch) => {
  return dispatch({
    type: TOGGLE_RECIPIENT_REATTEMPT,
    phone,
    reattempt,
  });
};

export const updateLastTextSent = () => (dispatch) => {
  return dispatch({
    type: UPDATE_LAST_TEXT_SENT,
  });
};

export const updateSelectedRecipient = (phone) => (dispatch) => {
  return dispatch({
    type: UPDATE_SELECTED_RECIPIENT,
    phone,
  });
};

export const selectNextRecipient = () => (dispatch) => {
  return dispatch({
    type: SELECT_NEXT_RECIPIENT,
  });
};

export const addItemToConversation =
  (phone, blob, currentscriptid) => (dispatch) => {
    return dispatch({
      type: ADD_ITEM_TO_CONVERSATION,
      currentscriptid,
      blob,
      phone,
    });
  };

export const updateActionableConversationCount = () => (dispatch) => {
  dispatch({
    type: UPDATE_ACTIONABLE_CONVERSATION_COUNT,
  });
};
export const setRecipientJustsentscript = (justSent) => (dispatch) => {
  return dispatch({
    type: TOGGLE_RECIPIENT_JUSTSENTSCRIPT,
    justSent,
  });
};

export const removeConversation = (phone) => (dispatch) => {
  return dispatch({
    type: REMOVE_CONVERSATION,
    phone: phone,
  });
};

export const setConversationTerminating = (phone) => (dispatch) => {
  return dispatch({
    type: SET_CONVO_TERMINATING,
    phone: phone,
  });
};
