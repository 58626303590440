import React from "react";

import InactivityModalContainer from "../../components/Inactivity/Inactivity.container";

import TopBarContainer from "../../components/TopBar.container";
import SurveyToolContainer from "../SurveyTool/SurveyTool.container";
// import Survey from "../SurveyTool/Survey.redux";

const Main = ({
  name,
  active,
  ...props
}) => {
  // console.log(props);
  return (
    <div className="dcm panel vertical">
      <TopBarContainer
        selectedCampaignName={name}
        campaignStatus={active}
      />
      <InactivityModalContainer
      />
      <SurveyToolContainer
        {...props}
      />
    </div>
  );
};

export default Main;
