import { addToArray, updateObject } from "../tools";
import {
  ADD_TEXTER_APP_EVENT,
  REMOVE_TEXTER_APP_EVENT,
  UPDATE_TEXTER_APP_EVENT,
  SET_TEXTER_APP_EVENT_VISIBILITY,
} from "./types";

const initState = {
  events: [],
  visible: {},
};

function eventsReducer(state = initState, action) {
  switch (action.type) {
    case ADD_TEXTER_APP_EVENT:
      return updateObject(state, {
        events: addToArray(state.events, action.event),
        visible: { ...state.visible, [action.event.id]: true },
      });
    case REMOVE_TEXTER_APP_EVENT:
      let newVis = { ...state.visible };
      delete newVis[action.id];
      return updateObject(state, {
        events: state.events.filter((item) => item.id === action.id),
        visible: newVis,
      });

    // TODO: Should we just do an additive system here? Only add updates to existing events, and filter older ones out.
    case UPDATE_TEXTER_APP_EVENT:
      return updateObject(state, {
        events: state.events.map((item) => {
          if (item.id === action.event.id) {
            return { ...item, ...action.event };
          }
          return item;
        }),
      });

    case SET_TEXTER_APP_EVENT_VISIBILITY:
      newVis = { ...state.visible, [action.id]: action.visible };
      return updateObject(state, {
        visible: newVis,
      });

    default:
      return state;
  }
}

export default eventsReducer;
