import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "./Button";

// TODO: Currenly the class is hard-coded, it should be extracted so the component can be reused.
class TwoPhaseButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primed: false,
    };
  }

  static propTypes = {
    callback: PropTypes.func.isRequired,
    timeout: PropTypes.number.isRequired,
    primedText: PropTypes.string.isRequired,
    defaultText: PropTypes.string.isRequired,
    updateState: PropTypes.func,
    unprime: PropTypes.bool
  }

  static getDerivedStateFromProps(props, state) {
    if (state.primed && props.unprime) {
      return {
        primed: false
      }
    };

    return null;
  }

  handleClick = () => {
    if (this.state.primed) {
      this.props.callback();
      this.updateState({
        primed: false
      });
    } else {
      this.primeTerminateButton();
    }
  };

  primeTerminateButton = () => {
    clearTimeout(this.state.unprimeTimeout);
    this.updateState({
      primed: true,
      unprimeTimeout: setTimeout(() => {
        this.updateState({
          primed: false,
        });
      }, this.props.timeout),
    });
  };

  /**
   * If a function is passed use that. If not use set state.
   */
  updateState = (newState) => {
    if (this.props.updateState) {
      this.props.updateState(newState);
    } else {
      this.setState(newState);
    }
  }

  render = () => (
    <Button onClick={this.handleClick} classSuffix="-red">
      {this.state.primed ? this.props.primedText : this.props.defaultText}
    </Button>
  );
}

export default TwoPhaseButton;
