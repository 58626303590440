import { fetchRequest } from "../../middleware/fetchMiddleware";
import {
  BOT_RESPONSE_DELAY,
  BOT_RESPONSE_PHRASES,
  TERMINATING_QUESTION_TYPES,
  TERMINATING_QUESTION_TYPES_STATUS_MAP,
  TERMINATION_DELAY,
  TOTAL_PHONE,
} from "../../util/constants";
import { isFollowup, setTimeoutCustom } from "../../util/helper";
import * as actionTypes from "./types";
import {
  getConversationNextScript,
  getRecipientTranscript,
  getCurrentQuestion,
  getScript,
  getNumberList,
  getRecipient,
} from "./selectors";
import {
  addItemToConversation,
  removeConversation,
  selectNextRecipient,
  setConversationTerminating,
  setRecipientJustsentscript,
  toggleRecipientReattempt,
  updateActionableConversationCount,
  updateConversationNextScript,
  updateLastTextSent,
} from "./actions";
import { EVENT_TYPES, addTexterAppEvent } from "../events/actions";
import { ScriptLogicError } from "../../util/scriptLogic";

export const getMyList = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest("GET_MY_LIST", "POST", "/getMyList", {
      campaignid: state.auth.campaignid,
      userid: state.auth.userid,
    })
  );
};

export const getMoreList = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest("GET_MORE_LIST", "POST", "/getMoreList", {
      campaignid: state.auth.campaignid,
      userid: state.auth.userid,
      total: TOTAL_PHONE,
    })
  );
};

export const endConversation =
  (phone, method, disposition) => (dispatch, getState) => {
    const state = getState();
    const script = state.campaign.script;
    const numbers = state.recipients.numbers;
    const currentId = numbers[phone].currentscriptid
      ? numbers[phone].currentscriptid
      : script[0].id;

    const currentQuestion = script.find((item) => item.id === currentId);

    let table = "phonelist";
    if (method === "panel") {
      table = "panel";
      method = "complete";
    }

    // When convo in panel make sure we use the right table
    if (currentQuestion && currentQuestion.type.includes("panel")) {
      table = "panel";
    }

    return dispatch(
      fetchRequest(
        actionTypes.END_CONVERSATION_PREFIX,
        "POST",
        "/endConversation",
        {
          campaignid: state.auth.campaignid,
          userid: state.auth.userid,
          phone,
          method,
          table,
          disposition
        }
      )
    );
  };

export const addRecipientToPanel = (phone) => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest(
      actionTypes.ADD_RECIPIENT_TO_PANEL_PREFIX,
      "POST",
      "/addRecipientToPanel",
      {
        campaignid: state.auth.campaignid,
        userid: state.auth.userid,
        phone,
      }
    )
  );
};

/**
 * Simple wrapper to addRecipientMessageToConversation which checks whether the given phone is handled by this agent.
 * @param {*} phone
 * @param {*} blob
 * @returns
 */
export const checkPhoneAndAddRecipientMessageToConversation =
  (phone, whatWasSaid) => (dispatch, getState) => {
    const state = getState();
    const phoneInList = phone in getNumberList(state);
    // console.log("is phone in list?", phone, phoneInList);
    if (phoneInList) {
      dispatch(updateConversationRecipient(
        phone,
        whatWasSaid,
        // NOTE: The current version of this
        getRecipient(state, phone).currentscriptid,
        {},
        new Date()
      ));
    }
  };

/**
 * Simple wrapper to terminateConversation which checks whether the given phone is handled by this agent.
 * @param {*} phone
 * @returns
 */
export const checkPhoneAndTerminateConversation =
  (phone) => (dispatch, getState) => {
    const state = getState();
    const phoneInList = phone in getNumberList(state);
    if (phoneInList) {
      dispatch(timedRemoveTerminatedConversation(phone));
    }
  };

/**
 * FINISH conversation means setting the final status and making a
 * request to the server.
 * @param {*} phone the conversation in question
 * @returns
 */
export const timedFinishConversation = (phone, method, disposition) => (dispatch) => {
  dispatch(setConversationTerminating(phone));
  setTimeoutCustom(() => {
    dispatch(endConversation(phone, method, disposition)).then(() => {
      dispatch(removeConversation(phone));
    });
  }, TERMINATION_DELAY);
};

/**
 * REMOVE conversation means setting the server has informed of a termination
 * and the convo needs removing from the list only.
 * @param {*} phone the conversation in question
 * @returns
 */
export const timedRemoveTerminatedConversation = (phone) => (dispatch) => {
  dispatch(setConversationTerminating(phone));
  setTimeoutCustom(() => {
    dispatch(removeConversation(phone));
  }, TERMINATION_DELAY);
};

export const updatedSelectedRecipientPhone = () => (dispatch) => {};

/**
 * For incoming messages theres no need hit the API
 * @param {*} phone
 * @param {*} whatWasSaid
 * @param {*} currentQuestionId
 * @param {*} misc
 * @param {*} date
 * @returns
 */
export const updateConversationRecipient =
  (phone, whatWasSaid, currentQuestionId, misc, date) => (dispatch) => {
    const item = {
      who: "recipient",
      what: whatWasSaid,
      date: date ? date : Date(),
      ...misc,
    };

    dispatch(addItemToConversation(phone, item, currentQuestionId));
    dispatch(updateActionableConversationCount());
  };

export const updateConversationSender =
  (phone, whatWasSaid, currentQuestionId) => (dispatch, getState) => {
    dispatch(setRecipientJustsentscript(phone, 1));

    const state = getState();
    const script = getScript(state);
    const currentQuestion = getCurrentQuestion(script, currentQuestionId);

    const scriptboolean = currentQuestionId ? true : false;
    const table = currentQuestion.type === "panel" ? "panel" : "phonelist";
    const questionType = currentQuestion.type;

    // Only send MMS on script messages, not on folloups
    // TODO: mmsMessageAlreadySent() might be a better way to go?
    const convo = getRecipientTranscript(state, phone);
    const includeMedia = !isFollowup(convo, currentQuestionId);
    
    const item = {
      who: "sender",
      what: whatWasSaid,
      scriptboolean, // TODO: what is this?
      date: Date(),
      currentscriptid: currentQuestionId,
      type: questionType,
      s160MediaId: includeMedia ? currentQuestion.s160MediaId : undefined,
    };

    return dispatch(
      // update conversation in our backend api
      appendToConversation(phone, currentQuestionId, item, table)
      ).then((data) => {

      // On error, mark the message as failed
      if (!data) { item.sendingFailed = true; }
      
      // Update count with every successful action!
      if (data && data.success){
        dispatch(updateActionableConversationCount());
      }

      if (TERMINATING_QUESTION_TYPES.includes(currentQuestion.type)) {
        dispatch(
          timedFinishConversation(
            phone,
            TERMINATING_QUESTION_TYPES_STATUS_MAP[currentQuestion.type].method,
            TERMINATING_QUESTION_TYPES_STATUS_MAP[currentQuestion.type].disposition,
          )
        );
      } else {
        dispatch(addItemToConversation(phone, item, currentQuestionId));
        dispatch(toggleRecipientReattempt(phone, false));

        if(data && data.success){
          dispatch(updateLastTextSent());
          // NOTE: This enables autoselecting the next conversation on send 
          // dispatch(selectNextRecipient());

          // NOTE: THis is a legacy check for a 0 for fake testing numbers. Keeping it around for posterity
          // this.props.selectedCampaignPhone.charAt(0) === "0" ||
          if (state.campaign.active === "sandbox") {
            dispatch(sendBotResponse(phone, currentQuestionId));
          }
        }
      }
    });
  };

export const sendBotResponse =
  (phone, currentQuestionId) => (dispatch, getState) => {
    // If the conversation is already terminating then we shouldn't do this:
    const currentQuestionType = getCurrentQuestion(getScript(getState())).type;
    console.log("Question type for bot", currentQuestionType)
    if (
      TERMINATING_QUESTION_TYPES.includes(
        currentQuestionType
      )
    ) {
      console.log("Terminating, so skipping the BOT response");
      return;
    }

    const el = Math.floor(Math.random() * BOT_RESPONSE_PHRASES.length);
    setTimeoutCustom(() => {
      dispatch(
        updateConversationBot(
          phone,
          BOT_RESPONSE_PHRASES[el],
          currentQuestionId
        )
      );
    }, BOT_RESPONSE_DELAY);
  };

export const updateConversationBot =
  (phone, whatWasSaid, currentQuestionId, misc, date) => (dispatch) => {
    const item = {
      who: "recipient",
      what: whatWasSaid,
      date: date ? date : Date(),
      currentscriptid: currentQuestionId,
      ...misc,
    };

    return dispatch(
      appendToConversation(phone, currentQuestionId, item, "phonelist")
    ).then((data) => {
      if (!data) { return; }
      dispatch(addItemToConversation(phone, item, currentQuestionId));
      dispatch(updateActionableConversationCount());
    });
  };

/**
 * New method to not double responibility for the recipient method
 * @returns
 */
export const updateConversationAccepted =
  (phone, whatWasSaid, currentQuestionId, misc, date) =>
  (dispatch, getState) => {
    // Can we move this to it's own method?
    const item = {
      who: "accepted",
      what: whatWasSaid,
      date: date ? date : Date(),
      currentscriptid: currentQuestionId,
      ...misc,
    };

    const state = getState();

    // If we have a scriptid then get the question type and include it into the blob
    // const script = thisthis.state.selectedCampaignScript;
    if (currentQuestionId) {
      const script = getScript(state);
      const currentQuestion = getCurrentQuestion(script, currentQuestionId);
      if (currentQuestion) {
        item["type"] = currentQuestion.type;
      }
    }

    const table = getRecipient(state, phone).optIn ? "panel" : "phonelist";

    // Get the next id
    let nextId;
    try {
      nextId = getConversationNextScript(state, phone, [
        ...getRecipientTranscript(state, phone),
        item,
      ]);
    } catch (e) {
      // If there's a script-error dispatch it so we can display it
      // throw e;
      if (e instanceof ScriptLogicError) {
        const errInfo = { 
          position: e.position,
          statement: e.statement,
          parsedCondition: e.parsedCondition,
          currentscriptid: e.currentscriptid,
          errorType: e.errorType, 
        };
        return dispatch(addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "accept_answer", errInfo));
      }
      return dispatch(addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "accept_answer"));
    }

    item["nextscriptid"] = nextId;

    return dispatch(
      appendToConversation(phone, currentQuestionId, item, table)
    ).then((data) => {
      if (!data) { return; }
      dispatch(addItemToConversation(phone, item, currentQuestionId));
      dispatch(setRecipientJustsentscript(0));
      dispatch(updateActionableConversationCount());
      dispatch(updateConversationNextScript(phone));
      // TODO: This is handled by the backend so it can just be ignored???
      // If the recipient said STOP then set to terminate the conversation.
      // if (whatWasSaid === "QUIT" || whatWasSaid === "END SURVEY") {
      //   thisthis.timedFinishConversation(
      //     phone,
      //     "terminated",
      //     TERMINATION_DELAY
      //   );
      // }
    });
  };

/**
 * API request for adding item to a recipient conversation
 * @returns {Promise}
 */
export const appendToConversation =
  (phone, currentscriptid, messageBlob, table) => (dispatch, getState) => {
    const state = getState();
    return dispatch(
      fetchRequest(
        actionTypes.APPEND_TO_CONVERSATION_PREFIX,
        "POST",
        "/appendToConversation",
        {
          item: messageBlob,
          currentscriptid,
          campaignid: state.auth.campaignid,
          phone: phone,
          table: table,
          status: state.campaign.active, // TODO: remove me
          userid: state.auth.userid,
        }
      )
    );
  };

export const addRecipientMessageToConversation =
  (phone, blob) => (dispatch) => {};
