import React from "react";

import SurveyComponent from "./SurveyTool.component";
import {
  updateConversationSender,
  updateConversationAccepted,
  updateConversationBot,
  updateConversationRecipient,
  timedFinishConversation,
  updatedSelectedRecipientPhone,
  timedRemoveTerminatedConversation,
  sendBotResponse,
  checkPhoneAndAddRecipientMessageToConversation,
  checkPhoneAndTerminateConversation,
} from "../../redux/recipients/thunks";

import {
  updateConversationNextScript,
  updateSelectedRecipient,
  updateActionableConversationCount,
} from "../../redux/recipients/actions";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { addTexterAppEvent } from "../../redux/events/actions";

export default reduxConnectedComponent(
  SurveyComponent,
  (state) => ({
    permissionlevel: state.auth.permissionlevel,
  }),
  {
    updateConversationSender,
    updateConversationAccepted,
    updateConversationNextScript,
    updateConversationBot,
    updateConversationRecipient,
    timedRemoveTerminatedConversation,
    timedFinishConversation,
    updatedSelectedRecipientPhone,
    updateSelectedRecipient,
    updateActionableConversationCount,
    sendBotResponse,
    checkPhoneAndAddRecipientMessageToConversation,
    checkPhoneAndTerminateConversation,
    addTexterAppEvent,
  }
);
