import booleans from "boolean-parser";
import MathParser from "expr-eval";
// import strToNum from 'parse-decimal-number';

const s160Parser = new MathParser.Parser({
    add: true,
    concatenate: true,
    conditional: true,
    divide: true,
    factorial: true,
    multiply: true,
    power: true,
    remainder: true,
    subtract: true,
    logical: false, // handled by boolean-parser
    comparison: true
})

export class ScriptLogicError extends Error {
  constructor(position, statement, parsedCondition, currentscriptid=null, type="general") {
    const msg=`ScriptLogicError: ${type} - statement at position ${position}. \nStatement: ${statement}. \nConditional: ${parsedCondition}. \nScript ID: ${currentscriptid}`
    super(msg);
    this.name = this.constructor.name;
    this.position = position;
    this.statement = statement;
    this.parsedCondition = parsedCondition;
    this.currentscriptid = currentscriptid;
    this.errorType = type;
  }
}

  /**
   * Runs an array of conditionals in order, returning the first successful conditional's GOTO
   * If none pass, returns false
   * Conditionals are in the form:
   * {
   *   conditition: 'x === y', (string, statement)
   *   goto: 'Q01' (string, qID)
   * }
   * @param {*} conditionals 
   * @param {*} variables 
   * @param {*} transcript 
   * @param {*} defaultGoto 
   * @returns Returns the next question id based on the script
   */
  export function evaluateConditionals(conditionals, variables, transcript, defaultGoto = null) {
    const debug = false
    for (let conditional of conditionals) {
      if (debug) console.log('conditional', conditional) //debug
      // evaluate {variables} and [answers]
      let parsedCondition = conditional.condition.replace(/\{([^\{\}]+)\}/g, replaceDataVariable) // matches {...}
                                                 .replace(/\[([^\[\]]+)\]/g, replaceAnswerVariable) // matches [...]

      // if (parsedCondition === -1000) {  }
      if (debug) console.log('parsedCondition', parsedCondition) //debug
      // compute all possible boolean combinations
      // boolean-parser returns an array of arrays of necessarily positive statements
      const possiblePositives = booleans.parseBooleanQuery(parsedCondition)
      // if all conditional within any group pass, return its GOTO question ID
      const conditionalPassed = possiblePositives.some(statements => {
        if (debug) console.log('statements', statements) //debug
        // evaluate each statement within the group
        const boolArray = statements.map((statement, i) => {
          // force empty conditions to eval false
          // (prevents EOF error when evaluating empty strings)
          if (statement === '') { statement = 'false'}
          try {
            return s160Parser.evaluate(statement)
          } catch(e) {
            let cid="";
            if (transcript.length > 0 && transcript[transcript.length - 1].currentscriptid) {
              cid = transcript[transcript.length - 1].currentscriptid;
            }
            let errorType = "conditional";
            if (transcript.length === 0) {
              // This is an initial conditional
              errorType = "initial conditional";
            }
            throw new ScriptLogicError(i, statement, parsedCondition, cid, errorType);
          }
        })
        if (debug) console.log('boolArray', boolArray) //debug
        // if all true, return true
        if (debug) console.log('boolArray.every(bool => bool)', boolArray.every(bool => bool)) //debug
        return boolArray.every(bool => bool)
      })
      if (conditionalPassed) {
        if (debug) console.log('Conditional passed. GOingTO:', conditional.goto) //debug
        return conditional.goto
      }
    }

    if (debug) console.log('All conditionals failed. default:', defaultGoto) //debug
    return defaultGoto // none passed

    function replaceDataVariable(match, key) {
      // key = text between curly braces (first paren match)
      return variables[key]
    }

    function replaceAnswerVariable(match, questionID) {
      // questionID = text between square braces (first paren match)
      // find first conversation entry where question answered
      const answer = transcript.find(entry => {
        return entry.who === 'accepted' && entry.currentscriptid === questionID
      })
      if (answer === undefined) {
        console.error(`Couldn't find an accepted answer for question ID ${questionID}. Typo in conditional?`)
        console.warn(`Force-evaluating [${questionID}] as -1000 so we can keep going.`)
        return -1000
      }
      return answer.value
    }
  }
