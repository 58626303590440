import React from "react";
import { Button } from "../common/Button";
import AgentStatisticsView from "./UserStatistics.redux";
import ScriptErrorEvents from "./ScriptErrorEvents";
//----------------------------------------------------------------------------------------------------
// DCMTopBar

export const TopBar = ({
  logout,
  selectedCampaignName,
  campaignStatus,
  campaignid,
  userid
}) => {
  return (
    <div className="dcm-topbar">
      <div className="dcm-topbar-brand">
        <img src="/s160_logo.png" alt="Survey160" />
      </div>
      <div className="dcm-topbar-events">
        <ScriptErrorEvents />
      </div>
      <div className="dcm-topbar-left dcm-topbar-campaign-info">
        
        survey: <span className="campaign">{selectedCampaignName} ({campaignid}) </span>
        {/* <br /> */}
        {campaignStatus === "sandbox" && (
          <div>survey status: &nbsp;<span className="sandbox">Sandbox</span></div>
        )}
        {campaignStatus === "active" && (
          <div>survey status: &nbsp;<span className="active">Active</span></div>
        )}
        user: <span className="userid">{userid}</span>
      </div>
      <AgentStatisticsView />
      <div className="dcm-topbar-right">
            <Button onClick={logout} classSuffix=" button-topbar">Log Out</Button>
      </div>
    </div>
  );
};
