import { Component } from "react";
import { SERVER_URLROOT} from "../../util/constants";
import io from "socket.io-client";
import { cleanPhoneNumber } from "../../util/helper";


class SocketHandler extends Component {
  componentDidMount() {
    this.socketSetup();
  }

  socketSetup = () => {
    this.socket = io(SERVER_URLROOT, {
      "force new connection": false,
      transports: ["websocket"]
    });

    this.socket.on("connect", this.connectHandler);
    this.socket.on("my response", this.responseHandler);
    this.socket.on(
      "terminated_conversation",
      this.terminateConversationHandler
    );
    this.socket.on("kick", this.kickHandler);

    this.socket.on("connection_error", (err) => {
      console.group("Socket Connection error");
      console.error("code", err.code);
      console.error("message", err.message);
      console.error("context", err.context);
      console.groupEnd();
    });

    this.socket.on("disconnect", (reason, details) => {
      console.group("Socket Disconnected");
      
      // the reason of the disconnection, for example "transport error"
      console.log(reason);
      
      if (details) {
        // the low-level reason of the disconnection, for example "xhr post error"
        console.log(details.message);
        // some additional description, for example the status code of the HTTP response
        console.log(details.description);
        // some additional context, for example the XMLHttpRequest object
        console.log(details.context);
      }
      
      console.groupEnd();
    });
  }

  connectHandler = () => {
    const { userid, twilioroom } = this.props;
    console.log("joinroom", twilioroom);
    this.socket.emit("joinroom", {
      userid: userid,
      twilioroom: twilioroom,
    });

    console.log("Socket connection made", this.socket);
  };

  /**
  There are 3 scenarios for this.
   - Force logout a disgruntled employee
   - Campaign is paused
   - Campaign quotas are reached
  */
  kickHandler = (data, callback) => {
    const { userid, twilioroom } = this.props;

    // Only kick users in the campaign or a specific user by name
    if (!("userid" in data) || data.userid === userid) {
      console.log("User was logged out!")
      // console.log("Forced Log Out");
      callback();
      // TODO: Does it make sense to just send a message with the data??
      if (!("userid" in data)) {
        window.alert("All users are being logged out of survey!");
      } else {
        window.alert("You are being logged out.");
      }

      this.props.redirectToLogin();
    }
  };

  /**
   * This callback removes the convo without a further request
   */
  terminateConversationHandler = (data, callback) => {
    callback();
    // console.debug("phone before cleaning", data.phone);
    data.phone = cleanPhoneNumber(data.phone);
    // console.debug("phone for termination", data.phone);

    // There should be a wrapper around terminate to check if this user is
    // handling this number
    this.props.checkPhoneAndTerminateConversation(data.phone);
  };

  // If we get an SMS from the websocket we check it out.
  // The server broadcasts an SMS to every sender associated to that campaign.
  // The client then checks whether it is responsible for the associated phone number
  // and takes care of the SMS if appropriate.
  responseHandler = (data, callback) => {
    callback();
    // Note: This must be uncommented for sockets to work
    data.phone = cleanPhoneNumber(data.phone);

    //   dispatch add to conversation
    // console.log("Message received", data.phone, data.data);
    this.props.checkPhoneAndAddRecipientMessageToConversation(data.phone, data.data);
  };

  componentWillUnmount() {
    const { userid, twilioroom } = this.props;

    this.socket.emit("leaveroom", {
      userid: userid,
      twilioroom: twilioroom,
    });
    this.socket.disconnect();
  }

  render() {
    return null;
  }
}

export default SocketHandler;
