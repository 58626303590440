import { SERVER_URLROOT } from "../util/constants";

export class S160Logger {
  static LogToServer(content, jwt) {
    const payload = {
      content,
      jwt,
      source: "sender",
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "application/json");

    const init = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload)
    };

    fetch(`${SERVER_URLROOT}/reactLogger`, init)
      .then((data) => {
        console.log(`Logged to server: ${content}`);
      })
      .catch((e) => {
        console.log(`S160Logger Error: Failed to log to server.`);
      });
  }
}
