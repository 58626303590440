import { togglePromptModal } from "../../redux/selectedRecipient/actions";
import { MessageControls } from "./MessageControls";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";

export default reduxConnectedComponent(
  MessageControls,
  (state) => ({
    promptModalVisible: state.selectedRecipient.promptModalVisible,
  }),
  {togglePromptModal} //togglePromptServerModal
);
