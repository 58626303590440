import React from "react";

export const MessageBubble = ({
  messageSource,
  messageContent,
  answerValue,  
  sendingFailed,
  mediaId,
  mediaURL,
}) => {
  switch (messageSource) {
    case "recipient":
      return <div className="message incoming">{messageContent}</div>;
    case "accepted":
      return (
        <div className="message meta-status answer-coding">
          Accepted:{" "}
          <span className="accepted-answer">
            {messageContent} ({answerValue})
          </span>
        </div>
      );
    case "media":
      return (
        <div className="message outgoing media">
          <img src={mediaURL} alt={mediaId} />
        </div>
      );
    default:
      return (
        <div className={`message outgoing ${sendingFailed ? 'sendingFailed' : ''}`}>
          {messageContent}
        </div>
      );
  }

  // var s = <div className="message incoming">{messageContent}</div>;
  // if (messageSource === "recipient") {
  //   s = <div className="message incoming">{messageContent}</div>;
  // }

  // if (messageSource === "accepted") {
  //   s = (
  //     <div className="message meta-status answer-coding">
  //       Accepted:{" "}
  //       <span className="accepted-answer">
  //         {messageContent} ({answerValue})
  //       </span>
  //     </div>
  //   );
  // }

  // return <div key={i}></div>;
};
