import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Redirect } from "react-router-dom";

import {
  login,
  logout,
  setUserid,
  setCampaignid,
  getLaunchedCampaigns,
  setAuthData,
} from "../redux/auth/actions";
import { ConnectedMain as MainContainer } from "./Main/Main.container";
import LoginScreen from "./Login/Login.component";

const Landing = (props) => (
  <div>
    <Route
      exact
      path="/"
      render={(p) => <RedirectCheck auth={props.auth} {...props} {...p} />}
    />
    <Route
      path="/login"
      render={(routeProps) => (
        <LoginScreen {...props.auth} {...props} {...routeProps} />
      )}
    />
    <Route
      path="/app"
      render={(routeProps) => (
        <MainContainer
          auth={props.auth}
          setAuthData={props.setAuthData}
          {...routeProps}
        />
      )}
    />
  </div>
);

const RedirectCheck = ({ auth, ...props }) => {
  return !auth.userid || !auth.jwt || !auth.campaignid ? (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/app",
        state: { from: props.location },
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  reduxHistory: state.router,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      logout,
      setUserid,
      getLaunchedCampaigns,
      setCampaignid,
      setAuthData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
