import React from "react";
import TwoPhaseButton from "../common/TwoPhaseButton";
import { Button } from "../common/Button";

/**
 * Simple wrapper for the TwoPhaseButton that unprimes it when the convo changes .
 * Specifically, when the currentPhone changes, we reset. 
 */
class TerminateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      previousPhone: this.props.currentPhone,
    });
  }

  componentDidUpdate() {
    if (this.state.previousPhone !== this.props.currentPhone) {
      this.setState({
        previousPhone: this.props.currentPhone,
      });
    }
  }

  render = () => (
    !this.props.disabled ? <TwoPhaseButton
      callback={() =>
        this.props.timedFinishConversation(
          this.props.currentPhone,
          "terminated",
          'agent terminate'
        )
      }
      timeout={3000}
      primedText="Confirm?"
      defaultText="Terminate"
      unprime={this.props.currentPhone !== this.state.previousPhone}
    /> : <Button disabled={true} classSuffix="-red">Terminate</Button>
  );
}

export default TerminateButton;
