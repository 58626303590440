import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";
import { AGENT_TIME_LOG_PREFIX, AGENT_STATISTICS_PREFIX } from "./types";
import { CLEAR_DATA } from "../common/types";

const agentTimeLog = loadStateReducer({
  [AGENT_TIME_LOG_PREFIX + "_IS_LOADING"]: "loading",
  [AGENT_TIME_LOG_PREFIX + "_HAS_ERROR"]: "error",
  [AGENT_TIME_LOG_PREFIX + "_POST_SUCCESS"]: "success",
});

const agentStatistics = loadStateReducer({
  [AGENT_STATISTICS_PREFIX + "_IS_LOADING"]: "loading",
  [AGENT_STATISTICS_PREFIX + "_HAS_ERROR"]: "error",
  [AGENT_STATISTICS_PREFIX + "_POST_SUCCESS"]: "success",
});
const initState = {};

// TODo: This is the result of shitty backend data packing!!!
const tempProcessing = data =>  {
  if (!data) {
    return {
      outboundMessageCount: 0,
      minuteCount: 0,
      lastUpdated: new Date()
    }
  }
  const {count, minutes} = data;
  return {
    outboundMessageCount: count[1],
    minuteCount: minutes[0],
    lastUpdated: new Date()
  }
}

const processed = (state = initState, action) => {
  switch (action.type) {
    case AGENT_STATISTICS_PREFIX + "_POST_SUCCESS":
        
      return tempProcessing(action.data);
    case CLEAR_DATA:
      return initState;
    default:
      return state;
  }
};

export default combineReducers({ agentTimeLog, agentStatistics, processed });
