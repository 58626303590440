import React, { Component } from "react";
import Conversation from "../../components/Conversation/ReduxConversation";
import { ConversationBlank } from "../../components/ConversationBlank";

import { deepCopy } from "../../util/helper";
import ConversationHead from "../../components/Conversation/ConversationHead";
import SocketHandler from "../../components/SocketHandler/SocketHandler.container";
import MediaContainer from "../../containers/MediaContainer";
import AgentTimeLogContainer from "../../components/AgentTimeLogManager/AgentTimeLog.container";
import AgentStatisticsManagerContainer from "../../components/AgentStatistics/AgentStatisticsManager";

class SurveyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "pending",
      listLoading: false,
      numbers: [],
    };
  }

  // Since the phonelist etc is now coming in via redux props
  // we have to turn it into state
  static getDerivedStateFromProps(props, state) {
    if (
      true ||
      !state.selectedCampaignCurrentList ||
      Object.keys(props.numbers).length !==
        Object.keys(state.selectedCampaignCurrentList).length
    ) {
      return {
        selectedCampaignName: props.name,
        selectedCampaignScript: [...props.script],
        selectedCampaignPrompt: props.prompt,
        // This should allow for subsequent list loads
        selectedCampaignCurrentList: {
          ...deepCopy(state.numbers),
          ...deepCopy(props.numbers),
        },
        selectedCampaignCurrentPhone: props.selectedRecipientPhone,
        selectedCampaignInitialConditionals: [...props.initialconditionals],
        selectedCampaignDefaultFirstQuestionId: props.defaultFirstQuestionId,
        twiliophone: props.twiliophone,
        subtwiliosid: props.subtwiliosid,
        subtwiliotoken: props.subtwiliotoken,
        campaignStatus: props.campaignStatus,
        twilioroom: props.subtwiliosid,
        organizationid: props.organizationid,
        actionableThreshold: parseInt(props.actionablethreshold),
        optInQuestion: props.script.some((item) => item.type === "panel"),
        jwt: props.jwt,
        campaignid: props.campaignid,
        userid: props.userid,
      };
    }
  }

  componentDidMount() {
    this.props.updateActionableConversationCount();

    // logs out the user on browser event
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("unload", (e) => {
        e.preventDefault();
        console.log("Acutally Unloading", e);
        this.props.logout();
        return (e.returnValue = "Are you sure you want to close?");
      });
    }
  }

  /**
   * Updates conversations with incoming messages from sockets (front-end only).
   * Agent accepted recipient answer (call API to update record).
   *
   * @param {*} who The entity adding the message. [recipient, sender (has it's own method), accept, bot]
   * @param {*} phone The recipient phone number
   * @param {*} whatWasSaid The body of the message
   * @param {*} currentQuestionId The script id which this message relates to
   * @param {*} misc Not sure what this is...
   * @param {*} date The datetime the message was received/accepted on
   * @returns The promise for the ajax call
   */
  updateConversationRecipient = async (
    who,
    phone,
    whatWasSaid,
    currentQuestionId,
    misc,
    date
  ) => {
    // console.log('currentQuestionId', currentQuestionId) //debug
    if (who === "recipient") {
      this.props.updateConversationRecipient(
        phone,
        whatWasSaid,
        currentQuestionId,
        misc,
        date
      );
    }
    if (who === "bot") {
      this.props.updateConversationBot(
        phone,
        whatWasSaid,
        currentQuestionId,
        misc,
        date
      );
    }
    if (who === "accepted") {
      this.props.updateConversationAccepted(
        phone,
        whatWasSaid,
        currentQuestionId,
        misc,
        date
      );
    }
  };

  render() {
    const selected =
      this.state.selectedCampaignCurrentList[
        this.state.selectedCampaignCurrentPhone
      ];

    // console.log("room", this.props.twilioroom);

    return (
      <div className="dcm panel vertical">
        {this.props.twilioroom && <SocketHandler />}
        {this.state.campaignStatus !== "sandbox" &&
          this.props.permissionlevel > 0 && <AgentTimeLogContainer />}

        {this.state.campaignStatus !== "sandbox" &&
          this.props.permissionlevel > 0 && <AgentStatisticsManagerContainer />}

        {selected && (
          <Conversation
            selectedCampaignPhone={this.state.selectedCampaignCurrentPhone}
            campaignStatus={this.state.campaignStatus}
            selectedCampaignPrompt={this.state.selectedCampaignPrompt}
            script={this.state.selectedCampaignScript}
            updateConversationSender={this.props.updateConversationSender}
            updateConversationNextScript={
              this.props.updateConversationNextScript
            }
            updateConversationRecipient={this.updateConversationRecipient}
            selectedListItem={
              this.state.selectedCampaignCurrentList[
                this.state.selectedCampaignCurrentPhone
              ] || null
            }
            selectedCampaignCurrentList={this.state.selectedCampaignCurrentList}
            updatePhone={this.props.updateSelectedRecipient}
            timedFinishConversation={this.props.timedFinishConversation}
            prompts={this.state.selectedCampaignPrompt}
            setDeactivatingOverlay={this.setDeactivatingOverlay}
            initialConditionals={this.state.selectedCampaignInitialConditionals}
            defaultFirstQuestionId={
              this.state.selectedCampaignDefaultFirstQuestionId
            }
            twilioroom={this.state.twilioroom}
            sendBotResponse={this.props.sendBotResponse}
            addTexterAppEvent={this.props.addTexterAppEvent}
          >
            <MediaContainer
              campaignid={this.state.campaignid}
              jwt={this.state.jwt}
            >
              <ConversationHead
                timedFinishConversation={this.props.timedFinishConversation}
                selectedCampaignCurrentPhone={
                  this.state.selectedCampaignCurrentPhone
                }
                {...selected}
              />
            </MediaContainer>
          </Conversation>
        )}

        {!selected && <ConversationBlank updatePhone={this.props.updateSelectedRecipient}/>}
      </div>
    );
  }
}

SurveyComponent.defaultProps = {
  numbers: [],
};

export default SurveyComponent;
