import React from "react";
import { MessageBubble } from "../../common/MessageBubble";
import TerminateButton from "../TerminateButton";
import { ScriptErrorEventPopupContainer } from "../ScriptErrorEvents";
class ConversationHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    conversation: [],
  };

  componentDidMount() {
    this.forceScrollToBottom();
  }

  componentDidUpdate() {
    this.forceScrollToBottom();
  }

  forceScrollToBottom() {
    document.getElementById("transcript").lastChild.scrollIntoView({
      behavior: "smooth",
    });
  }

  render = () => {
    const { conversation, status, disabled, mediaList } = this.props;

    return (
      <div className="dcm-sender-conversation-top pane">
        <div id="transcript">
          <div className="message meta-status">
            {conversation.length === 0 && !disabled && "Interaction Pending"}
            {conversation.length > 0 && "Interaction Started"}
            {disabled && "No conversation selected"}
          </div>
          {conversation.map((r, i) => {
            
            let mmsBubble = null;
            if (mediaList && r.s160MediaId && mediaList[r.s160MediaId]) {
              mmsBubble = <MessageBubble
                messageSource={"media"}
                mediaId={mediaList[r.s160MediaId].media_name}
                mediaURL={mediaList[r.s160MediaId].gcloud_url}
                sendingFailed={r.sendingFailed}
                key={`bubble-media-${i}`}
              />;
            }

            return (
              <React.Fragment>
                {mmsBubble}
                <MessageBubble
                  messageSource={r.who}
                  messageContent={r.what}
                  answerValue={r.value}
                  sendingFailed={r.sendingFailed}
                  key={`bubble-${i}`}
                />
              </React.Fragment>
            );
          })}

          {status === "closed" && (
            <div className="dcm-sender-conversation-status">
              Interaction Closed
            </div>
          )}
          {status === "terminated" && (
            <div className="dcm-sender-conversation-status">
              Interaction Terminated
            </div>
          )}
        </div>
        <TerminateButton
          currentPhone={this.props.selectedCampaignCurrentPhone}
          timedFinishConversation={this.props.timedFinishConversation}
          disabled={disabled}
        />
        <ScriptErrorEventPopupContainer />
      </div>
    );
  };
}

export default ConversationHead;