import { TOGGLE_PROMPT_MODAL, UPDATE_CURRENT_SCRIPT_ITEM } from "./types";


const initState = {
  promptModalVisible: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_SCRIPT_ITEM:
      return {
        selectedScriptItem: "",
      };

      case TOGGLE_PROMPT_MODAL:
      return {
        promptModalVisible: action.visible,
      };
    
    default:
      return state;
  }
};