import React from "react";
import PropTypes from "prop-types";

import { Recipient } from "./Recipient";
import { TerminatingIndicator } from "./TerminatingIndicator";
import { RecipientListLoadingIndicator } from "../RecipientListLoadingIndicator";

/**
 *
 * DCMPhoneList
 *
 * The phone list is rendered such that any phone numbers which are awaiting sender action,
 * meaning the conversation either has length 0 or has positive length but it's the sender's turn,
 * go first in the list. Numbers awaiting recipient action are put at the end.
 */
export const RecipientList = ({
  selectedCampaignCurrentPhone,
  selectedCampaignCurrentList = {},
  updatePhone,
  sortedPhones= []
}) => {
  return (
    <div className="dcm-sender-phonelist pane">
      <RecipientListLoadingIndicator />
      {sortedPhones.length === 0 && <div className="dcm-sender-phonelist-empty">No numbers available</div>}
      {sortedPhones.map((ky, i) => {
        let conversation = selectedCampaignCurrentList[ky].conversation;
        const reattempt = selectedCampaignCurrentList[ky].reattempt;
        // let awaitingRecipientAction =
        //   conversation.length > 0 &&
        //   (conversation[conversation.length - 1].who === "recipient" ||
        //     conversation[conversation.length - 1].who === "accepted" ||
        //     reattempt);
        
        const awaitingAnswerCoding = conversation.length > 0 && conversation[conversation.length - 1].who === "recipient";
        const awaitingNextSend = conversation.length > 0 && conversation[conversation.length - 1].who === "accepted";
        const awaitingReattempt = conversation.length > 0 && reattempt;

        return (
          <Recipient
            conversationStarted={conversation.length > 0}
            selected={selectedCampaignCurrentPhone === ky}
            panel={selectedCampaignCurrentList[ky].optIn}
            name={selectedCampaignCurrentList[ky].name}
            phone={ky}
            updatePhone={updatePhone}
            key={`recipient-row-${i}`}
            awaitingAnswerCoding={awaitingAnswerCoding}
            awaitingNextSend={awaitingNextSend}
            awaitingReattempt={awaitingReattempt}
          >
            {selectedCampaignCurrentList[ky].terminating && (
              <TerminatingIndicator key={`recipient-terminating-${i}`} />
            )}
          </Recipient>
        );
      })}
    </div>
  );
};

RecipientList.propTypes = {
  updatePhone: PropTypes.func.isRequired,
  selectedCampaignCurrentPhone: PropTypes.string.isRequired,
  selectedCampaignCurrentList: PropTypes.object.isRequired,
};