import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export const reduxConnectedComponent = (
  WrappedComponent,
  mapStateToProps,
  actions = {}
) => {
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actions, dispatch);
  };

  const component = (props) => <WrappedComponent {...props} />;
  return connect(mapStateToProps, mapDispatchToProps)(component);
};
