import React from "react";

import { AcceptAnswerButton } from "./Answers/AcceptAnswerButton";
import { AnswerBox } from "./Answers/AnswerBox";
import { MessageControls } from "./Message/MessageControls";
import { MessageBox } from "./Message/MessageBox";
import RecipientListContainer from "../screens/RecipientList/RecipientList.container";
import ConversationHead from "./Conversation/ConversationHead";
import { ScriptErrorEventPopupContainer } from "./ScriptErrorEvents";

//----------------------------------------------------------------------------------------------------
// DCMConversationBlank
// This appears when there's no phone number selected.
// This should only occur when there are no phone numbers remaining and so it's really
// just a dummy screen where nothing needs to work.

export const ConversationBlank = ({updatePhone}) => {
  return (
    <div>
      <ConversationHead disabled={true} />
      <div className={`dcm-sender-conversation-bottom pane panel disabled`}>
        <RecipientListContainer disabled={true} updatePhone={updatePhone}/>
        <div className="pane panel vertical">
          <MessageBox disabled={true} />
          <MessageControls disabled={true} />
        </div>
        <div className="pane panel vertical">
          <AnswerBox disabled={true} />
          <AcceptAnswerButton disabled={true} />
        </div>
      </div>
      <ScriptErrorEventPopupContainer />

    </div>
  );
};
