import React from 'react';
import { connect } from "react-redux";
import PromptServerModal from "./PromptServerModal";
import { togglePromptModal } from "../../redux/selectedRecipient/actions";
import { bindActionCreators } from 'redux';

const PromptModalContainer = (props) => <PromptServerModal {...props} />;

const mapStateToProps = (state) => ({
  promptModalVisible: state.selectedRecipient.promptModalVisible
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ togglePromptModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromptModalContainer);
