import { Component } from "react";
import { minuteToMilliseconds, setIntervalCustom } from "../util/helper";
import PropTypes from "prop-types";

/**
 * Since there's multiple intervals now I made this generic
 */
class RecurringCallbackManager extends Component {
  componentDidMount() {
    // console.log(this.props);
    // Start the 60 second timer to a agent hour charge if campaign is active
    if (this.props.executeTaskAtStart === true) {
      // console.log("task executed");
      this.props.task();
    }
    this.interval = this.startRecurringTask(
      minuteToMilliseconds(this.props.intervalTime)
    );
  }

  componentWillUnmount() {
    // Stop charging agent hours
    this.clearTaskInterval();
  }

  clearTaskInterval = () => {
    // Remove any previously set intervals
    let prevId = localStorage.getItem(this.props.intervalName);
    if (prevId) {
      window.clearInterval(parseInt(prevId));
    }
  };

  startRecurringTask = (seconds) => {
    this.clearTaskInterval();

    const intervalid = setIntervalCustom(this.props.task, seconds);
    // save to localstorage
    localStorage.setItem(this.props.intervalName, intervalid);
    // console.log("Interval set", intervalid)
    return intervalid;
  };

  render = () => null;
}

RecurringCallbackManager.propTypes = {
  task: PropTypes.func.isRequired,
  intervalName: PropTypes.string.isRequired,
  intervalTime: PropTypes.number.isRequired,
  executeTaskAtStart: PropTypes.bool
};

export default RecurringCallbackManager;
