import { fetchRequest } from "../../middleware/fetchMiddleware";
import { AGENT_STATISTICS_PREFIX, AGENT_TIME_LOG_PREFIX } from "./types";

export const logAgentMinute = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest(AGENT_TIME_LOG_PREFIX, "POST", "/logAgentTime", {
      campaignid: state.auth.campaignid,
      userid: state.auth.userid,
    })
  );
};

export const getAgentStatistics = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest(AGENT_STATISTICS_PREFIX, "POST", "/getIndividualAgentStatistics", {
      campaignid: state.auth.campaignid,
      userid: state.auth.userid,
    })
  );
};
